import request from '@/utils/request'

// 查询脱敏数据列表
export function listData(query) {
  return request({
    url: '/mask/data/list',
    method: 'get',
    params: query
  })
}

// 查询脱敏数据详细
export function getData(id) {
  return request({
    url: '/mask/data/' + id,
    method: 'get'
  })
}

// 查询脱敏数据详细
export function getMaskRoleData(id) {
  return request({
    url: '/mask/data/role/' + id,
    method: 'get'
  })
}
// 保存数据脱敏
export function saveMaskRoleData(data) {
  return request({
    url: '/mask/data/role',
    method: 'put',
    data: [data]
  })
}



// 新增脱敏数据
export function addData(data) {
  return request({
    url: '/mask/data',
    method: 'post',
    data: data
  })
}

// 修改脱敏数据
export function updateData(data) {
  return request({
    url: '/mask/data',
    method: 'put',
    data: data
  })
}

// 角色状态修改
export function changeMaskStatus(id, status) {
  const statusData = {
    id,
    status
  }
  console.log(statusData)
  return request({
    url: '/mask/data',
    method: 'put',
    data: statusData
  })
}

// 删除脱敏数据
export function delData(id) {
  return request({
    url: '/mask/data/' + id,
    method: 'delete'
  })
}
