<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="列名">
                <a-input v-model="queryParams.columnName" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="列名描述">
                <a-input v-model="queryParams.columnDesc" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>

            <a-col :md="8" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="handleQuery"><a-icon type="search"/>查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo"/>重置</a-button>
                <a-button style="margin-left: 8px" type="primary" @click="handleExport"
                          v-hasPermi="['system:mask:export']">
                  <a-icon type="download"/>导出
                </a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <!--        <a-button type="primary" :disabled="single" @click="handleUpdate" v-hasPermi="['system:mask:edit']">
                  <a-icon type="edit" />修改
                </a-button>-->

        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"/>
      </div>

      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="columnName"
        :columns="columns"
        :data-source="dataList"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered">
        <span slot="status" slot-scope="text, record">
          <a-popconfirm
            ok-text="是"
            cancel-text="否"
            @confirm="handleStatusChange(record)"
          >
            <span slot="title">确认<b>{{ record.status == '1' ? '启用' : '停用' }}</b>{{ record.columnName }}的脱敏吗?</span>
            <a-switch checked-children="开" un-checked-children="关" :checked="record.status == 1"/>
          </a-popconfirm>
        </span>
        <span slot="direction" slot-scope="text, record">
            <!-- 重写自定义了个字典组件-->
           <custom-dict-tag :options="customDict.DirectionEnum"  :value="record.direction"/>
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="handleUpdate(record)" v-hasPermi="['system:mask:edit']">
            <a-icon type="edit"/>
            修改
          </a>
          <!--          <a-divider type="vertical" v-hasPermi="['system:role:remove']" />
                    <a @click="handleDelete(record)" v-hasPermi="['system:role:remove']">
                      <a-icon type="delete" />
                      删除
                    </a>-->
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParams.pageNum"
        :total="total"
        :page-size="queryParams.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
    <!-- 添加或修改脱敏数据对话框 -->
    <a-drawer :visible.sync="open" width="700px" @close="cancel">
      <a-divider orientation="left">
        <b>{{ title }}</b>
      </a-divider>
      <a-form-model ref="form" :model="form" :rules="rules" label-width="100px">
        <a-form-model-item label="列名" prop="columnName">
          <a-input v-model="form.columnName" readonly :disabled='true' placeholder="请输入要脱敏的列名"/>
        </a-form-model-item>
        <a-form-model-item label="列名描述" prop="columnDesc">
          <a-input v-model="form.columnDesc" placeholder="请输入列名描述"/>
        </a-form-model-item>
        <a-form-model-item label="脱敏方向" prop="direction">
          <a-radio-group v-model="form.direction">
            <a-radio v-for="(item, index) in directionOptions" :key="index" :value="item.value">{{ item.label }}
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="起始位置" prop="startIndex">
          <a-input-number v-model="form.startIndex" :min="1" :maxlength="2" placeholder="请输入脱敏起始位置"
                          :precision="0" style="width: 100%"/>
        </a-form-model-item>
        <a-form-model-item label="脱敏字符数" prop="wordNum">
          <a-input-number v-model="form.wordNum" :min="1" :maxlength="2" placeholder="请输入脱敏字符数" :precision="0"
                          style="width: 100%"/>
        </a-form-model-item>
        <a-form-model-item label="是否开启" prop="status" required>
          <a-switch v-model="form.status" :active-value='1' :inactive-value='0'></a-switch>
        </a-form-model-item>
        <a-form-model-item label="备注" prop="remark">
          <a-input v-model="form.remark" placeholder="请输入备注"/>
        </a-form-model-item>
      </a-form-model>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">确 定</a-button>
          <a-button @click="cancel">取 消</a-button>
        </a-space>
      </div>
    </a-drawer>
  </page-header-wrapper>
</template>


<script>
import {listData, getData, delData, addData, updateData, changeMaskStatus} from "@/api/system/mask";
import {changeJobStatus} from "@/api/monitor/job";
import {tableMixin} from '@/store/table-mixin';
import { mapGetters } from 'vuex';
import CustomDictTag from "@/components/DictCustomTag";


export default {
  name: "Data",
  components: {
    CustomDictTag,
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  mixins: [tableMixin],
  data() {
    return {
      selectedRowKeys: [],
      selectedRows: [],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 脱敏数据表格数据
      dataList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        columnName: null,
        columnDesc: null,
        direction: null,
        startIndex: null,
        wordNum: null,
        status: null,
        creator: null,
        modifier: null,
        isDeleted: null,
        version: null,
      },
      // 表单参数
      form: {},
      directionOptions: [{
        "label": "从左向右",
        "value": 0
      }, {
        "label": "从右向左",
        "value": 1
      }],
      // 表单校验
      rules: {
        columnName: [
          {required: true, message: "要脱敏的列名不能为空", trigger: "blur"}
        ],
        columnDesc: [
          {required: true, message: "列名描述不能为空", trigger: "blur"}
        ],
        direction: [{
          required: true,
          message: '脱敏方向不能为空',
          trigger: 'change'
        }],
        startIndex: [
          {required: true, message: "脱敏起始位置不能为空", trigger: "blur"}
        ],
        wordNum: [
          {required: true, message: "脱敏字符数不能为空", trigger: "blur"}
        ]
      },
      columns: [
        {
          title: '列名',
          dataIndex: 'columnName',
          align: 'center'
        },
        {
          title: '列名描述',
          dataIndex: 'columnDesc',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '脱敏方向',
          dataIndex: 'direction',
          scopedSlots: {customRender: 'direction'},
          align: 'center'
        },
        {
          title: '起始位置',
          dataIndex: 'startIndex',
          align: 'center'
        },
        {
          title: '脱敏字符数',
          dataIndex: 'wordNum',
          align: 'center'
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: {customRender: 'status'},
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: {customRender: 'createTime'},
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '20%',
          scopedSlots: {customRender: 'operation'},
          align: 'center'
        }
      ]
    };
  },
  created() {
    this.getList();
  },
  methods: {
    /** 查询脱敏数据列表 */
    getList() {
      this.loading = true;
      listData(this.queryParams).then(response => {
        this.dataList = response.data.records;
        this.total = response.data.total;
        this.loading = false;
      });
    },
    onShowSizeChange(current, pageSize) {
      this.queryParams.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParams.pageNum = current
      this.queryParams.pageSize = pageSize
      this.getList()
    },
    // 任务状态修改
    handleStatusChange(row) {
      row.status = row.status == "0" ? "1" : "0";
      changeMaskStatus(row.id, row.status).then(() => {
        this.$message.success("操作成功！");
        this.getList();
      }).catch(function () {
        row.status = row.status =="0" ? "1" : "0";
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        columnName: null,
        columnDesc: null,
        direction: null,
        startIndex: null,
        wordNum: null,
        status: null,
        creator: null,
        createTime: null,
        modifier: null,
        updateTime: null,
        isDeleted: null,
        version: null,
        remark: null
      };
      //this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParams = {
        pageNum: 1,
        pageSize: 10,
        columnName: null,
        columnDesc: null,
        direction: null,
        startIndex: null,
        wordNum: null,
        status: null,
        creator: null,
        modifier: null,
        isDeleted: null,
        version: null,
      },
        this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加脱敏数据";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getData(id).then(response => {
        this.form = response.data;
        this.open = true;
        this.title = "修改脱敏数据";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id != null) {
            this.form.status = this.form.status ? 1 : 0;
            updateData(this.form).then(response => {
              if (response.success) {
                this.$message.success("修改成功");
                this.open = false;
                this.getList();
              } else {
                this.$message.error(response.message);
              }
            });
          } else {
            addData(this.form).then(response => {
              if (response.success) {
                this.$message.success("新增成功");
                this.open = false;
                this.getList();
              } else {
                this.$message.error(response.message);
              }
            });
          }
        }
      });
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download('mask/data/export', {
        ...this.queryParams
      }, `data_${new Date().getTime()}.xlsx`)
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },


  }
};
</script>
